import React from 'react';
import './App.css';

function App() {
	return (
		<div className="App">
			<header className="App-header">
				<p>
					Welcome to MegaMileyStudios!
				</p>
			</header>

			<p>
				There is not much here yet, check back later<br />
			</p>
		</div>
	);
}

export default App;
